import React, { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import { graphql, PageProps } from "gatsby";

import { LocalisedPageContext } from "../../types/PageTypes";
import { ProductLandingPageV2QueryProps } from "./types";
import StructuredData from "../../components/StructuredData";
import ProductLandingHero from "../../components/ProductLandingHero";
import BlockRenderer from "../../components/BlockRenderer";
import { AlgoliaInstantSearch } from "../../components/Algolia";
import PopularProducts from "../../components/PopularProducts";
import { Equalizer } from "../../images/icons/equalizer";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { useIntersect } from "../../utils/hooks";
import { Translations } from "../../utils/algoliaProductsQuery";
import ProductRatingsAndReviewsScript from "../../components/ProductRatingsAndReviewsScript";
import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event11 } from "../../analytics/event11";

export const data = graphql`
  query productLandingPageV2($_id: String, $language: String, $bestSeller: String, $market: String) {
    productLandingPageV2: sanityProductLandingPageV2(_id: { eq: $_id }) {
      ...ProductLandingPageV2FieldsFull
    }
    ...LocalizedContext
    bestSellingProducts: allSanityProductV2(
      filter: { tags: { elemMatch: { tagName: { in: [$bestSeller] } } } }
      sort: { order: DESC, fields: _id }
    ) {
      nodes {
        _id
        name
        smartProductId
        paSmartProductId
        paUPC
        paRetailerCategory
        subBrand {
          name
        }
        slug {
          current
        }
        images {
          alt
          ...Image
        }
      }
    }
  }
`;

const ProductLandingPageV2 = (props: PageProps<ProductLandingPageV2QueryProps, LocalisedPageContext>) => {
  const language = props.pageContext.language || "";
  const { htmlLang } = useSiteMetadata(language);
  const productPage = props.data.productLandingPageV2;
  const pageTitle =
    productPage.seo?.metaTitle || productPage.headline.primaryText + " " + productPage.headline.secondaryText;
  const pageDescription = productPage.seo?.metaDescription || productPage._rawIntroduction[0]?.children[0]?.text || "";
  const staticLabels = { ...props.data.sanityLabels?.searchLabels, ...props.data.sanityLabels?.productLabels };
  const { filtersBlockHeading, popularProductsBlockHeading } = staticLabels;
  const { nodes: products } = props.data["bestSellingProducts"];

  const { sanitySiteSettings } = useContext(LocalizedContext);

  // This is to make sure banner works of internationalisation
  const lineOne = productPage.headline.primaryText + " ";
  const lineTwo = productPage.headline.secondaryText === null ? " " : productPage.headline.secondaryText + " ";
  const lineThree = productPage.headline.tertiaryText === null ? " " : productPage.headline.tertiaryText + " ";

  const ref = useRef(null);
  const isSticky = useIntersect(ref);

  const [renderFilters, setRenderFilters] = useState(false);

  const MemoizedAlgoliaInstantSearch = useMemo(() => {
    return (
      <AlgoliaInstantSearch
        data={props}
        filtersQuery={`lang: ${language} AND pageType:productV2`}
        configureFiltersQuery={`lang:${language} AND pageType:productV2`}
        bestSellerQuery={`lang:${language} AND pageType:productV2 AND popularity:${Translations[language].bestSeller}`}
        hitsPerPage={12}
        renderFilters={!renderFilters}
      />
    );
  }, []);

  const handleFiltersPanel = () => {
    setRenderFilters(true);
  };

  useEffect(() => {
    if (products && products.length > 0) {
      event11(products, sanitySiteSettings?.brandName, "Product Page (popular product list)");
    }
  }, [products]);

  return (
    <Layout localizedContext={{ ...props.pageContext, ...props.data }} pageName={pageTitle} pageType={"products"}>
      <StructuredData
        type={"WebPage"}
        name={pageTitle}
        description={pageDescription}
        data={productPage._rawHeroImage}
        customData={productPage.seo.jsonld}
        language={htmlLang}
        slug={`/ ${productPage.heading} / `}
      />
      <SEO
        title={pageTitle}
        description={pageDescription}
        imageUrl={productPage._rawHeroImage?.asset?.url}
        imageAlt={productPage._rawHeroImage?.alt}
      />
      <ProductRatingsAndReviewsScript />

      {/* PAGE HERO */}
      <ProductLandingHero
        image={productPage._rawHeroImage}
        text={productPage._rawIntroduction}
        lineOne={lineOne}
        lineTwo={lineTwo}
        lineThree={lineThree}
      />

      {/* PRODUCTS LISTING */}
      <section className="rx-landing--product">
        <div className={`rx-product--filters-stickyElement ${isSticky ? "isSticky" : null}`} ref={ref}></div>
        <section className="rx-product-listing">
          {renderFilters && MemoizedAlgoliaInstantSearch}
          <div className={`rx-product--filters`} hidden={renderFilters}>
            <div className="rx-product--filters-search">
              <button className="rx-product--filters-btn" onClick={handleFiltersPanel}>
                {filtersBlockHeading}
                <Equalizer />
              </button>
            </div>
          </div>
          {products && (
            <PopularProducts
              heading={popularProductsBlockHeading || "Most Popular Products"}
              products={products}
              language={language}
            />
          )}
        </section>
      </section>

      {/* LANDING SECTIONS */}
      <section className="rx-landing--teasers">
        {productPage.landingSections &&
          productPage.landingSections.map((section, index) => (
            <BlockRenderer key={"landingSection" + index} section={section} />
          ))}
      </section>
    </Layout>
  );
};

export default memo(ProductLandingPageV2);
